/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'next_question_hover': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<defs><filter x="-10.4%" y="-10.4%" width="129.2%" height="129.2%" filterUnits="objectBoundingBox" id="svgicon_next_question_hover_a"><feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.0784313725 0 0 0 0 0.0980392157 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1"/></filter><circle pid="0" id="svgicon_next_question_hover_b" cx="12" cy="12" r="12"/></defs><g _fill="none" fill-rule="evenodd"><g transform="matrix(0 1 1 0 2.05 2)"><use _fill="#000" filter="url(#svgicon_next_question_hover_a)" xlink:href="#svgicon_next_question_hover_b"/><use _fill="#2D335B" xlink:href="#svgicon_next_question_hover_b"/></g><path pid="1" d="M15.879 14l-3.536-3.536a1 1 0 011.415-1.414l4.065 4.066a1.25 1.25 0 010 1.768l-4.065 4.066a1 1 0 11-1.415-1.414L15.88 14z" _fill="#F9F9F9" fill-rule="nonzero"/></g>'
  }
})
